import React from 'react'
import { Session } from '@supabase/supabase-js'
import { Profile } from '../services/api'
import { Button } from '@radix-ui/themes'
import { useNavigate } from 'react-router-dom'

interface ProfileIndicatorProps {
  session?: Session | null
  profile?: Profile | null
}

const ProfileIndicator: React.FC<ProfileIndicatorProps> = ({
  session,
  profile,
}) => {
  const navigate = useNavigate()

  if (!session) {
    return (
      <Button 
        size="2" 
        variant="solid" 
        color="blue"
        onClick={() => navigate('/login')}
      >
        Sign in
      </Button>
    )
  }

  // Determine initials
  let initials = ''
  if (profile && profile.first_name && profile.last_name) {
    initials = `${profile.first_name.charAt(0).toUpperCase()}${profile.last_name.charAt(0).toUpperCase()}`
  } else if (session.user.email) {
    initials = session.user.email.charAt(0).toUpperCase()
  }

  return (
    <div className="flex items-center">
      <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer hover:opacity-80 overflow-hidden">
        {session.user.user_metadata.avatar_url ? (
          <img
            src={session.user.user_metadata.avatar_url}
            alt={session.user.email}
            className="w-full h-full object-cover"
          />
        ) : (
          <span className="text-sm font-medium">{initials}</span>
        )}
      </div>
    </div>
  )
}

export default ProfileIndicator
