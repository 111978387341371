import { Session } from '@supabase/supabase-js'
import React, { ReactNode } from 'react'
import { Profile } from '../services/api'
import AppHeader from './AppHeader'
import BottomNavigation from './BottomNavigation'

interface PageContainerProps {
  children: ReactNode
  session?: Session | null
  profile?: Profile | null
  className?: string
  headerLeftElement?: string
  headerTransparent?: boolean
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  session,
  profile,
  className = '',
  headerLeftElement = 'icon',
  headerTransparent = false,
}) => {
  const isAuthenticated = Boolean(session?.user)

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden ${className}`}
      style={{
        background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)',
      }}
    >
      <AppHeader
        session={session}
        profile={profile}
        leftElement={headerLeftElement}
        isTransparent={headerTransparent}
      />
      <div className="flex-1 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        {children}
      </div>
      {isAuthenticated && (
        <div className="flex-shrink-0">
          <BottomNavigation />
        </div>
      )}
    </div>
  )
}

export default PageContainer
