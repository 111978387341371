import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Section,
  Text,
} from '@radix-ui/themes'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/png/favicon-9.png'

const LandingPage: React.FC = () => {
  return (
    <Box
      className="min-h-screen flex flex-col"
      style={{
        background:
          'linear-gradient(135deg, #EDF5FE 0%, #F4F2FB 50%, #FBF0F7 100%)',
      }}
    >
      {/* Main content area with flex-grow to push footer down */}
      <Container
        size="4"
        className="flex-grow flex flex-col items-center p-4 sm:p-8 w-full"
      >
        {/* Brand section */}
        <Flex
          align="center"
          justify="center"
          gap="2"
          className="hover:scale-[1.02] transition-all duration-300 mb-16 sm:mb-20"
        >
          <Box>
            <img src={logo} alt="hotlines.ai logo" className="w-8 h-8" />
          </Box>
          <Heading
            size="6"
            weight="bold"
            className="bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent"
          >
            hotlines.ai
          </Heading>
        </Flex>

        {/* Hero section */}
        <Section size="3" className="w-full">
          <Flex direction="column" align="center" className="max-w-3xl mx-auto">
            {/* Hero content */}
            <Flex direction="column" align="center" gap="4" mb="8">
              <Heading
                size="9"
                align="center"
                className="bg-gradient-to-b from-gray-900 to-gray-600 bg-clip-text text-transparent leading-[1.1] tracking-tight font-medium"
              >
                AI agent storefronts for you
              </Heading>
              <Heading
                size="7"
                align="center"
                weight="medium"
                className="bg-gradient-to-b from-gray-600 to-gray-500 bg-clip-text text-transparent max-w-xxl font-normal"
              >
                Want effortless 24/7 support that customers love?
              </Heading>
              <Heading
                size="5"
                align="center"
                weight="regular"
                className="bg-gray-500 bg-clip-text text-transparent max-w-xl font-light"
              >
                Deploy AI agents across calls, chats, and your website.
              </Heading>
            </Flex>

            {/* CTA section */}
            <Flex direction="column" align="center" gap="5">
              <Button
                size="4"
                variant="surface"
                className="w-full sm:w-auto shadow-xl hover:shadow-2xl transition-all duration-300 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
              >
                <Link
                  to="https://calendar.app.google/xgMQ3XsS6vc5e1WM6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-10 sm:px-12 py-4 sm:py-5 text-lg sm:text-xl font-semibold text-white"
                >
                  Book a Meeting
                </Link>
              </Button>

              <Text size="2" color="gray" className="tracking-wide">
                Have access?{' '}
                <Link
                  to="/login"
                  className="text-blue-600 hover:text-blue-800 font-medium transition-colors"
                >
                  Login
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Section>
      </Container>

      {/* Footer */}
      <Box className="border-t border-gray-200/50 bg-white/30 backdrop-blur-sm">
        <Container size="4" p="4">
          <Flex
            direction={{ initial: 'column', sm: 'row' }}
            justify="between"
            align="center"
            gap="4"
          >
            <Flex
              wrap="wrap"
              justify={{ initial: 'center', sm: 'start' }}
              gap="6"
            >
              <Link
                to="/privacy-policy"
                className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-and-conditions"
                className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
              >
                Terms & Conditions
              </Link>
              <Link
                to="/about"
                className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
              >
                About Us
              </Link>
              <Link
                to="/contact-us"
                className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
              >
                Contact Us
              </Link>
            </Flex>
            <Text
              size="2"
              color="gray"
              align={{ initial: 'center', sm: 'left' }}
              className="tracking-wide"
            >
              © 2025 AXTRON LABS PRIVATE LIMITED. All rights reserved.
            </Text>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default LandingPage
