import { Session } from '@supabase/supabase-js'
import { useEffect, useState, useCallback } from 'react'
import { createChatWithBusiness, findChatWithBusiness } from '../services/api'

const useChatCreator = (businessId: string | null, session: Session | null) => {
  const [chatId, setChatId] = useState<string | null>(null)
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )
  const [isCreatingChat, setIsCreatingChat] = useState(false)

  const checkForExistingChat = useCallback(async () => {
    if (!businessId || !session || isCreatingChat) return

    try {
      const existingChatId = await findChatWithBusiness(businessId, session)
      if (existingChatId) {
        setChatId(existingChatId)
      }
    } catch (error) {
      console.error('Error checking for existing chat:', error)
    }
  }, [businessId, session, isCreatingChat])

  useEffect(() => {
    if (businessId && session) {
      checkForExistingChat()
    }
  }, [businessId, session, checkForExistingChat])

  const createChat = async () => {
    if (!businessId || !session || isCreatingChat || chatId) return

    setIsCreatingChat(true)
    try {
      const newChatId = await createChatWithBusiness(businessId, session)
      if (newChatId) {
        setChatId(newChatId)
      } else {
        throw new Error('Chat creation failed')
      }
    } catch (error) {
      console.error('Error creating chat:', error)
    } finally {
      setIsCreatingChat(false)
    }
  }

  const handleUserMessageTyping = (
    e: React.ChangeEvent<HTMLInputElement>,
    setMessage: (msg: string) => void
  ) => {
    setMessage(e.target.value)

    if (e.target.value.length > 10 && !chatId && !isCreatingChat && session) {
      if (typingTimeout) clearTimeout(typingTimeout)

      setTypingTimeout(
        setTimeout(() => {
          createChat()
        }, 500)
      )
    }
  }

  return {
    chatId,
    handleUserMessageTyping,
    isCreatingChat,
    createChat,
    checkForExistingChat,
  }
}

export default useChatCreator
