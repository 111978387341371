import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Heading, Text, Box, Flex, Section } from '@radix-ui/themes'
import logo from '../assets/png/favicon-9.png'

const ContactUsPage: React.FC = () => {
  return (
    <Box 
      className="min-h-screen flex flex-col"
      style={{
        background: 'linear-gradient(135deg, #EDF5FE 0%, #F4F2FB 50%, #FBF0F7 100%)',
      }}
    >
      <Container size="4" className="flex-grow flex flex-col items-center p-4 sm:p-8 w-full">
        {/* Header with logo */}
        <Flex align="center" justify="center" gap="2" className="mb-12">
          <Link to="/" className="flex items-center gap-2 hover:opacity-80 transition-opacity">
            <img src={logo} alt="hotlines.ai logo" className="w-8 h-8" />
            <Heading size="6" weight="bold" className="bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
              hotlines.ai
            </Heading>
          </Link>
        </Flex>

        {/* Main content */}
        <Section size="3" className="w-full max-w-2xl mx-auto">
          <Box className="space-y-8">
            {/* Contact Information Section */}
            <Box className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm">
              <Heading size="6" mb="4">Contact Information</Heading>
              <Box className="space-y-6">
                <Box>
                  <Text weight="bold" size="3">Support Email</Text>
                  <Text as="p" size="2" color="gray">
                    <Link to="mailto:support@hotlines.ai" className="text-blue-600 hover:text-blue-800">
                      support@hotlines.ai
                    </Link>
                  </Text>
                  <Text as="p" size="1" color="gray" className="mt-1">
                    You can contact us anytime to opt out of communications.
                  </Text>
                </Box>

                <Box>
                  <Text weight="bold" size="3">Company Details</Text>
                  <Text as="p" size="2" color="gray">AXTRON LABS PRIVATE LIMITED</Text>
                </Box>

                <Box>
                  <Text weight="bold" size="3">Office Address</Text>
                  <Text as="p" size="2" color="gray">
                    Mumbai 400021, India
                  </Text>
                </Box>
              </Box>
            </Box>

            {/* Quick Links Section */}
            <Box className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm">
              <Heading size="6" mb="4">More information</Heading>
              <Flex direction="column" gap="2">
                <Link to="/privacy-policy" className="text-blue-600 hover:text-blue-800">Privacy Policy</Link>
                <Link to="/terms-and-conditions" className="text-blue-600 hover:text-blue-800">Terms & Conditions</Link>
              </Flex>
            </Box>
          </Box>
        </Section>
      </Container>

      {/* Footer */}
      <Box className="text-center p-6">
        <Text size="2" color="gray">© 2024 AXTRON LABS PRIVATE LIMITED. All rights reserved.</Text>
      </Box>
    </Box>
  )
}

export default ContactUsPage 