import { ChatBubbleIcon, PaperPlaneIcon } from '@radix-ui/react-icons'
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Separator,
  Text,
} from '@radix-ui/themes'
import { Session } from '@supabase/supabase-js'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useMessagingManager from '../hooks/useMessagingManager'
import {
  getBusinessInfo,
  getTransactionsUserBusiness,
  Profile,
} from '../services/api'
import {
  Business,
  BusinessActions,
  BusinessType,
  CarTransaction,
  FlightTransaction,
  TableTransaction,
  Transaction,
} from '../types'
import { handleSpecialScenarios } from '../utils/specialScenarioHandler'
import AppHeader from './AppHeader'
import BusinessAvatar from './BusinessAvatar'
import CoverImage from './BusinessProfile/CoverImage'
import LoadingComponent from './LoadingComponent'
import PageContainer from './PageContainer'
import RestaurantCarousel from './RestaurantCarousel'
import CarCard from './TransactionCards/CarCard'
import FlightCard from './TransactionCards/FlightCard'
import TableCard from './TransactionCards/TableCard'

export interface BusinessProfileProps {
  session?: Session | null
  profile?: Profile | null
}

const BusinessProfile: React.FC<BusinessProfileProps> = ({
  session,
  profile,
}) => {
  const { username } = useParams<{ username: string }>()
  const navigate = useNavigate()

  // Add authentication flag
  const isAuthenticated = Boolean(session?.user)

  // State for business information:
  const [businessData, setBusinessData] = useState<Business | null>(null)
  const [expanded, setExpanded] = useState(false)
  const [transactionData, setTransactionData] = useState<Transaction[] | null>(
    null
  )
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState<string>('')

  // Responsive flag – consider mobile if window.innerWidth < 768
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Always initialize messaging manager, it will handle null session internally
  const messagingManager = useMessagingManager(
    businessData?.id ?? '',
    session ?? null
  )

  const {
    chatId,
    isCreatingChat,
    isMessageSending,
    sendMessage,
    handleUserMessageTyping,
  } = messagingManager

  // Fetch all required data concurrently.
  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        setLoading(true)

        // Always fetch business info
        const businessResponse = await getBusinessInfo(username ?? '')
        setBusinessData(businessResponse.data)

        // Only fetch transactions if authenticated
        if (isAuthenticated && session && businessResponse.data) {
          const transactionResponse = await getTransactionsUserBusiness(
            session.user.id,
            businessResponse.data.id,
            session
          )
          setTransactionData(transactionResponse.data)
        }
      } catch (error) {
        console.error('Error in concurrent fetching:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchBusiness()
  }, [username, session, isAuthenticated])

  const handleActionClick = async (
    action: BusinessActions,
    processedActionString?: string
  ) => {
    if (!isAuthenticated) {
      // Redirect to login if not authenticated
      navigate('/login')
      return
    }

    const contentToSend = processedActionString || action.action
    await handleSendMessage(contentToSend)
  }

  const handleSendMessage = async (content: string = '') => {
    if (!isAuthenticated) {
      navigate('/login')
      return
    }

    if (message.length === 0 && content.length === 0) return

    const messageToSend = content || message

    await sendMessage(messageToSend, async (newChatId: string) => {
      await handleSpecialScenarios(messageToSend, session!)
      navigate(`/chat/${newChatId}`, { state: { businessInfo: businessData } })
    })

    setMessage('')
  }

  if (loading) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement="icon"
      >
        <LoadingComponent />
      </PageContainer>
    )
  }

  // Fallback if data is missing.
  if (!businessData) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement="back"
        headerTransparent={false}
      >
        <div className="p-4">
          <h1 className="text-xl font-semibold">Business not found</h1>
          <p className="text-gray-600 mt-2">
            The business you're looking for doesn't exist or has been removed.
          </p>
        </div>
      </PageContainer>
    )
  }

  // Render mobile layout.
  if (isMobile) {
    return (
      <PageContainer
        session={session}
        profile={profile}
        headerLeftElement="back"
        headerTransparent={false}
      >
        <div className="flex flex-col flex-grow min-h-full justify-between">
          <div>
            {/* Profile Section */}
            <Box p="4">
              <Flex gap="4" align="center">
                <BusinessAvatar
                  src={businessData.avatar}
                  username={businessData.username}
                  size="lg"
                />
                <Box>
                  <Flex align="center" gap="2">
                    <Heading>{businessData.name}</Heading>
                    {businessData.type === BusinessType.AI && (
                      <Badge variant="outline" color="blue" radius="full">
                        AI
                      </Badge>
                    )}
                  </Flex>
                  <Text>{businessData.username}</Text>
                </Box>
              </Flex>
              <Flex
                maxWidth="100%"
                mt="4"
                align="center"
                justify="between"
                gap="2"
              >
                <div
                  className={`relative flex-1 ${expanded ? '' : 'truncate'}`}
                  onBlur={() => setExpanded(false)}
                  onClick={() => setExpanded(!expanded)}
                >
                  {businessData.description}
                </div>
              </Flex>
            </Box>
            <Separator my="3" size="4" />

            {/* Conditional messaging UI */}
            {isAuthenticated ? (
              <Card className="m-4 shadow-sm" variant="surface">
                <Flex direction="column" gap="1">
                  <Heading
                    size="3"
                    className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500
                        text-transparent bg-clip-text font-extrabold tracking-tight mb-2"
                  >
                    Start a chat ✨
                  </Heading>

                  <Flex
                    gap="2"
                    wrap="nowrap"
                    justify="start"
                    className="overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
                  >
                    {businessData.actions?.length > 0 ? (
                      businessData.actions.map((action: BusinessActions) => (
                        <Button
                          size="3"
                          radius="large"
                          key={action.name}
                          className="flex-shrink-0"
                          variant="surface"
                          onClick={() => handleActionClick(action)}
                        >
                          {action.name}
                        </Button>
                      ))
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Flex>
                {/* Search Input */}
                <Flex justify="center" align="center" gap="2" mt="4">
                  <div className="max-w-screen-md mx-auto flex bg-white w-11/12 rounded-xl px-4 py-2 shadow-sm border border-gray-400 items-center">
                    <input
                      type="text"
                      placeholder={`How can we help you today?`}
                      value={message}
                      onChange={(e) => handleUserMessageTyping(e, setMessage)}
                      className="bg-transparent w-full outline-none"
                    />
                  </div>
                  <IconButton
                    onClick={() => handleSendMessage()}
                    loading={isMessageSending || isCreatingChat}
                    aria-label="Send Message"
                    // disabled={!message.length}
                    disabled={true}
                  >
                    <PaperPlaneIcon />
                  </IconButton>
                </Flex>
              </Card>
            ) : (
              <Card className="m-4 shadow-sm" variant="surface">
                <Flex direction="column" gap="3" align="center">
                  <Text size="3">Sign in to chat with this business</Text>
                  <Button
                    onClick={() => navigate('/login')}
                    variant="solid"
                    color="blue"
                  >
                    Sign In
                  </Button>
                </Flex>
              </Card>
            )}

            {chatId && (
              <div className="flex justify-center px-4">
                <Button
                  variant="ghost"
                  onClick={() => {
                    // Navigate to chat using the retrieved or existing chat ID
                    navigate(`/chat/${chatId}`, {
                      state: { businessInfo: businessData },
                    })
                  }}
                >
                  View past interactions
                </Button>
              </div>
            )}

            <Separator my="3" size="4" />

            {/* Conditional transactions section */}
            {isAuthenticated &&
              transactionData &&
              transactionData.length > 0 && (
                <Flex align="center" justify="center" gap="1" p="1" px="4">
                  <Text size="4" weight="bold">
                    Highlights
                  </Text>
                  <Badge radius="full">{transactionData.length}</Badge>
                </Flex>
              )}

            <Flex dir="col" align="center" justify="start" gap="4" p="4">
              {!transactionData || transactionData.length === 0 ? (
                <div />
              ) : (
                transactionData?.map((data) => {
                  switch (data.type) {
                    case 'flight':
                      const flight = data as FlightTransaction
                      return (
                        <FlightCard
                          key={flight.id}
                          from={flight.data.from}
                          to={flight.data.to}
                          date={flight.data.date}
                          time={flight.data.time}
                          status={flight.data.status}
                          number={flight.data.number}
                          pnr={flight.data.pnr}
                          toImage={flight.data.toImage}
                          actions={flight.actions}
                          onActionClick={handleActionClick}
                        />
                      )
                    case 'table':
                      const table = data as TableTransaction
                      return (
                        <TableCard
                          key={table.id}
                          date={table.data.date}
                          time={table.data.time}
                          people={table.data.people}
                          status={table.data.status}
                          actions={table.actions}
                          onActionClick={handleActionClick}
                        />
                      )
                    case 'car':
                      const car = data as CarTransaction
                      return (
                        <CarCard
                          key={car.id}
                          number_plate={car.data.number_plate}
                          last_service={car.data.last_service}
                          icon={car.icon}
                          model={car.data.model}
                          actions={car.actions}
                          onActionClick={handleActionClick}
                        />
                      )
                    default:
                      return null
                  }
                })
              )}
            </Flex>
          </div>
        </div>
      </PageContainer>
    )
  }

  // Render desktop layout.
  return (
    <div
      className={'flex flex-col justify-between min-h-screen'}
      style={{
        background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)',
        minHeight: '100vh',
      }}
    >
      <AppHeader
        session={session}
        profile={profile}
        leftElement="back"
        isTransparent={true}
        position="absolute"
      />
      {/* Cover Image */}
      <CoverImage businessData={businessData} />

      {/* Scrollable Content Wrapper */}
      <div className="flex -mt-80 justify-center px-4 py-10">
        <Card
          className="absolute top-40 left-1/2 -translate-x-1/2 w-full max-w-4xl p-6"
          variant="surface"
        >
          <Box p="4">
            <Flex gap="4" align="center" justify="between">
              <Flex gap="4" align="center">
                <BusinessAvatar
                  src={businessData.avatar}
                  username={businessData.username}
                  size="lg"
                />
                <Box>
                  <Flex align="center" gap="2">
                    <Heading>{businessData.name}</Heading>
                    {businessData.type === BusinessType.AI && (
                      <Badge variant="outline" color="blue" radius="full">
                        AI
                      </Badge>
                    )}
                  </Flex>
                  <Text>{businessData.username}</Text>
                </Box>
              </Flex>
              {chatId && (
                <div className="flex px-4">
                  <Button
                    radius="large"
                    size="3"
                    variant="surface"
                    color="blue"
                    onClick={() => {
                      // Navigate to chat using the retrieved or existing chat ID
                      navigate(`/chat/${chatId || username}`, {
                        state: { businessInfo: businessData },
                      })
                    }}
                  >
                    <ChatBubbleIcon />
                    View past interactions
                  </Button>
                </div>
              )}
            </Flex>
            <Flex
              maxWidth="100%"
              mt="4"
              align="center"
              justify="between"
              gap="2"
            >
              <div>{businessData.description}</div>
            </Flex>
          </Box>
          <Separator my="3" size="4" />

          <Flex gap="4" justify="center">
            {/* Carousel */}
            {businessData.carousel_1 && (
              <Flex width="50%" direction="column" gap="2" align="center">
                {businessData.carousel_1 && (
                  <RestaurantCarousel photosArray={businessData.carousel_1} />
                )}
                {businessData.carousel_2 && (
                  <RestaurantCarousel photosArray={businessData.carousel_2} />
                )}
              </Flex>
            )}
            <Flex width="50%" gap="2" direction="column" align="center">
              {/* Conditional messaging UI */}
              {isAuthenticated ? (
                <Card
                  className="flex flex-col shadow-sm w-full mx-auto"
                  variant="surface"
                >
                  {/* Chat Header */}
                  <Heading
                    size="3"
                    className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500
                 text-transparent bg-clip-text font-extrabold tracking-tight mb-4"
                  >
                    Start a chat ✨
                  </Heading>

                  {/* Action Buttons */}
                  {businessData.actions?.length > 0 && (
                    <Flex wrap="wrap" gap="2">
                      {businessData.actions.map((action: BusinessActions) => (
                        <Button
                          size="3"
                          radius="large"
                          key={action.name}
                          variant="surface"
                          onClick={() => handleActionClick(action)}
                        >
                          {action.name}
                        </Button>
                      ))}
                    </Flex>
                  )}

                  {/* Search Input + Send Button */}
                  <Flex justify="between" align="center" gap="3" mt="4">
                    <div className="flex-grow flex bg-white rounded-xl px-4 py-2 shadow-sm border border-gray-300">
                      <input
                        type="text"
                        placeholder="How can we help you today?"
                        value={message}
                        onChange={(e) => handleUserMessageTyping(e, setMessage)}
                        className="bg-transparent w-full outline-none"
                      />
                    </div>
                    <IconButton
                      onClick={() => handleSendMessage()}
                      loading={isMessageSending || isCreatingChat}
                      aria-label="Send Message"
                      disabled={!message.length}
                    >
                      <PaperPlaneIcon />
                    </IconButton>
                  </Flex>
                </Card>
              ) : (
                <Card className="m-4 shadow-sm" variant="surface">
                  <Flex direction="column" gap="3" align="center">
                    <Text size="3">Sign in to chat with this business</Text>
                    <Button
                      onClick={() => navigate('/login')}
                      variant="solid"
                      color="blue"
                    >
                      Sign In
                    </Button>
                  </Flex>
                </Card>
              )}

              <Separator my="3" size="4" />
              {/* Conditional transactions section */}
              {isAuthenticated &&
                transactionData &&
                transactionData.length > 0 && (
                  <Flex
                    align="center"
                    justify="start"
                    gap="1"
                    p="1"
                    className="w-full"
                  >
                    <Text size="4" weight="bold">
                      Highlights
                    </Text>
                    <Badge radius="full">{transactionData.length}</Badge>
                  </Flex>
                )}

              <Flex
                dir="col"
                align="start"
                justify="start"
                gap="4"
                className="w-full"
              >
                {!transactionData || transactionData.length === 0 ? (
                  <div />
                ) : (
                  transactionData?.map((data) => {
                    switch (data.type) {
                      case 'flight':
                        const flight = data as FlightTransaction
                        return (
                          <FlightCard
                            key={flight.id}
                            from={flight.data.from}
                            to={flight.data.to}
                            date={flight.data.date}
                            time={flight.data.time}
                            status={flight.data.status}
                            number={flight.data.number}
                            pnr={flight.data.pnr}
                            toImage={flight.data.toImage}
                            actions={flight.actions}
                            onActionClick={handleActionClick}
                          />
                        )
                      case 'table':
                        const table = data as TableTransaction
                        return (
                          <TableCard
                            key={table.id}
                            date={table.data.date}
                            time={table.data.time}
                            people={table.data.people}
                            status={table.data.status}
                            actions={table.actions}
                            onActionClick={handleActionClick}
                          />
                        )
                      case 'car':
                        const car = data as CarTransaction
                        return (
                          <CarCard
                            key={car.id}
                            number_plate={car.data.number_plate}
                            last_service={car.data.last_service}
                            icon={car.icon}
                            model={car.data.model}
                            actions={car.actions}
                            onActionClick={handleActionClick}
                          />
                        )
                      default:
                        return null
                    }
                  })
                )}
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </div>
    </div>
  )
}

export default BusinessProfile
