import { Session } from '@supabase/supabase-js'
import { useState } from 'react'
import { sendMessage } from '../services/api'

const useSendMessage = (chatId: string | null, session: Session | null) => {
  const [isMessageSending, setIsMessageSending] = useState(false)
  const MAX_RETRIES = 5
  const RETRY_DELAY = 500

  const sendMessageHook = async (content: string, attempts = 0) => {
    if (!content.trim() || !chatId || !session) return

    setIsMessageSending(true)

    try {
      await sendMessage(chatId, content, session)
    } catch (error) {
      console.error(
        `Message sending failed (Attempt ${attempts + 1}/${MAX_RETRIES}):`,
        error
      )

      if (attempts < MAX_RETRIES) {
        console.warn(`Retrying in ${RETRY_DELAY}ms...`)
        setTimeout(() => sendMessageHook(content, attempts + 1), RETRY_DELAY)
      } else {
        console.error('Max retries reached. Message failed to send.')
        setIsMessageSending(false)
      }
      return
    }

    setIsMessageSending(false)
  }

  return { sendMessageHook, isMessageSending }
}

export default useSendMessage
