import {
  Box,
  Container,
  Flex,
  Heading,
  Section,
  Text,
} from '@radix-ui/themes'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/png/favicon-9.png'

const AboutUsPage: React.FC = () => {
  return (
    <Box
      className="min-h-screen flex flex-col"
      style={{
        background:
          'linear-gradient(135deg, #EDF5FE 0%, #F4F2FB 50%, #FBF0F7 100%)',
      }}
    >
      <Container size="4" className="flex-grow py-8 px-4">
        {/* Logo section */}
        <Link to="/">
          <Flex
            align="center"
            justify="center"
            gap="2"
            className="hover:scale-[1.02] transition-all duration-300 mb-8"
          >
            <Box>
              <img src={logo} alt="hotlines.ai logo" className="w-8 h-8" />
            </Box>
            <Heading
              size="6"
              weight="bold"
              className="bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent"
            >
              hotlines.ai
            </Heading>
          </Flex>
        </Link>

        <Section size="3" className="max-w-4xl mx-auto">
          <Heading
            size="8"
            className="mb-8 bg-gradient-to-b from-gray-900 to-gray-600 bg-clip-text text-transparent"
          >
            About Hotlines.ai
          </Heading>

          <Flex direction="column" gap="6">
            <Box>
              <Heading size="6" className="mb-3 text-gray-800">
                Our Mission
              </Heading>
              <Text className="text-gray-600 leading-relaxed">
                At Hotlines.ai, we're revolutionizing customer support by making AI-powered communication accessible to businesses of all sizes. Our mission is to help companies provide exceptional 24/7 customer service while reducing operational costs and improving customer satisfaction.
              </Text>
            </Box>

            <Box>
              <Heading size="6" className="mb-3 text-gray-800">
                What We Do
              </Heading>
              <Text className="text-gray-600 leading-relaxed">
                We provide cutting-edge AI agent storefronts that seamlessly integrate with your existing business operations. Our platform enables businesses to deploy sophisticated AI agents across multiple channels - phone calls, chat interfaces, and websites - ensuring consistent, high-quality customer interactions around the clock.
              </Text>
            </Box>

            <Box>
              <Heading size="6" className="mb-3 text-gray-800">
                Our Technology
              </Heading>
              <Text className="text-gray-600 leading-relaxed">
                Our AI agents are powered by state-of-the-art language models and machine learning technologies, trained to understand context, handle complex queries, and provide personalized responses. We continuously improve our technology to ensure our AI agents deliver natural, helpful, and efficient customer service experiences.
              </Text>
            </Box>

            <Box>
              <Heading size="6" className="mb-3 text-gray-800">
                Why Choose Us
              </Heading>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>Seamless 24/7 customer support coverage</li>
                <li>Reduced operational costs</li>
                <li>Consistent customer experience across all channels</li>
                <li>Easy integration with existing systems</li>
                <li>Scalable solutions that grow with your business</li>
                <li>Advanced analytics and insights</li>
              </ul>
            </Box>

            {/* Contact Information Section */}
            <Box className="bg-white/80 backdrop-blur-sm rounded-lg p-6 shadow-sm mt-6">
              <Heading size="6" mb="4">Contact Information</Heading>
              <Box className="space-y-6">
                <Box>
                  <Text weight="bold" size="3">Support Email</Text>
                  <Text as="p" size="2" color="gray">
                    <Link to="mailto:support@hotlines.ai" className="text-blue-600 hover:text-blue-800">
                      support@hotlines.ai
                    </Link>
                  </Text>
                  <Text as="p" size="1" color="gray" className="mt-1">
                    You can contact us anytime to opt out of communications.
                  </Text>
                </Box>

                <Box>
                  <Text weight="bold" size="3">Company Details</Text>
                  <Text as="p" size="2" color="gray">AXTRON LABS PRIVATE LIMITED</Text>
                </Box>

                <Box>
                  <Text weight="bold" size="3">Office Address</Text>
                  <Text as="p" size="2" color="gray">
                    Mumbai 400021, India
                  </Text>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Section>
      </Container>
    </Box>
  )
}

export default AboutUsPage 