import { Session } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'
import useChatCreator from './useChatCreator'
import useSendMessage from './useSendMessage'

const useMessagingManager = (businessId: string, session: Session | null) => {
  const { chatId, isCreatingChat, createChat, handleUserMessageTyping } =
    useChatCreator(businessId, session)

  const { sendMessageHook, isMessageSending } = useSendMessage(chatId, session)
  const [pendingMessage, setPendingMessage] = useState<string | null>(null)
  const [postChatCallback, setPostChatCallback] = useState<
    ((chatId: string) => void) | null
  >(null)

  /**
   * Sends a free-input message. If no chat exists, it first creates one.
   * If an `onChatReady` callback is provided, store it to run once chatId is set,
   *  or run it immediately if we can.
   *
   */
  const sendMessage = async (
    message: string,
    onChatReady?: (chatId: string) => void
  ) => {
    if (!message.trim()) return

    if (!chatId) {
      setPendingMessage(message) // Store message while waiting for chat creation
      if (onChatReady) setPostChatCallback(() => onChatReady) // Store callback
      await createChat()
    } else {
      await sendMessageHook(message) // Send immediately if chat exists
      if (onChatReady) onChatReady(chatId) // Run callback immediately if chat exists
    }
  }

  /**
   * When `chatId` updates, check if there is a pending message and send it.
   * Note that this has a drawback, if the user sends more than one message while this is waiting
   * the first message is lost. Use `isCreatingChat` and `isMessageSending` to prevent this at source.
   */
  useEffect(() => {
    if (chatId && pendingMessage) {
      sendMessageHook(pendingMessage)
      setPendingMessage(null) // Clear pending message after sending
    }
    if (chatId && postChatCallback) {
      postChatCallback(chatId) // Run stored callback after chat is created
      setPostChatCallback(null) // Reset callback
    }
  }, [chatId, pendingMessage, postChatCallback, sendMessageHook])

  /**
   * Handles action button clicks, sending a predefined message.
   */
  const triggerActionMessage = async (action: string) => {
    const message = `User clicked: ${action}`
    await sendMessage(message)
  }

  return {
    chatId,
    isCreatingChat,
    isMessageSending,
    sendMessage,
    triggerActionMessage,
    handleUserMessageTyping,
  }
}

export default useMessagingManager
